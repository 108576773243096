<template>
  <!-- leetcode 无评论 -->
  <div>
    <div class="house-header grey-header fixed-header broadcast-header">
      <div class style="padding: 0 18px">
        <div class="house-nav">
          <router-link to="/">
            <img
              class="logo"
              src="@/assets/img/logowhite.png"
              style="vertical-align: middle"
            />
          </router-link>
          <div class="broadcurson">
            正在学习：{{ $store.state.sectionName }}
          </div>
          <div class="haslogin layui-breadcrumb">
            <!-- <el-badge class="item" is-dot> </el-badge> -->
            <router-link to="/center/mylesson">
              <img
                class="icon"
                src="@/assets/img/mylesson-icon.png"
                style="
                  width: 24px;
                  display: inline-block;
                  vertical-align: middle;
                  margin-top: 2px;
                "
              />
              <span style="font-size: 13px; margin-right: 20px">我的课程</span>
            </router-link>
            <router-link to="/center/imformation">
              <img class="user" :src="this.$store.state.userInfo.avatar" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div id="broadcast" class="broadcast clearfix">
      <div
        id="video-con"
        class="video-con"
        :style="{
          'min-height': computedWindowHeight,
          'background-color': 'rgb(243, 245, 246)',
        }"
      >
        <div>
          <div id="polyvideo" class="video-body"></div>
        </div>
        <!-- <div class="analysis">
          <div class="analysis-box" v-if="solvingIdea">
            <span>思路分析</span>
            <p :class="analysisopen ? 'open' : ''">{{ solvingIdea }}</p>
          </div>
          <div class="analysis-box" v-if="comment">
            <span class="v">面试点评</span>
            <p :class="viewopen ? 'open' : ''">{{ comment }}</p>
          </div>
          <div class="analysis-box" style="margin: 0" v-if="remarks">
            <span class="v">备注</span>
            <p :class="viewopen ? 'open' : ''">{{ remarks }}</p>
          </div>
        </div> -->
      </div>
      <div id="resize">||</div>
      <div id="code-con" class="code-con">
        <div class="code-tabs nav_title" id="nav_title">
          <ul class="nav_title_wrapper">
            <li v-if="haveCode" :class="codeShow ? 'active' : ''" class="lee">
              Java Code
              <!-- <i class="el-icon-caret-bottom"></i> -->
              <!-- <ul>
                <li
                  :class="activeCode == 'Java' ? 'active' : ''"
                  @click="switchCodeStyle('Java')"
                >
                  Java
                </li>
                <li
                  :class="activeCode == 'CPlusCode' ? 'active' : ''"
                  @click="switchCodeStyle('CPlusCode')"
                >
                  C++
                </li>
                <li
                  :class="activeCode == 'Python' ? 'active' : ''"
                  @click="switchCodeStyle('Python')"
                >
                  Python
                </li>
              </ul> -->
            </li>
          </ul>
        </div>
        <div v-if="haveCode" v-show="codeShow" class="tab-show code-show">
          <div id="codeView" v-highlight>
            <pre>
              <code class="cpp" style="transform: translateY(-15px);" v-text="code"></code>
            </pre>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
import footerLayout from "@/views/layout/children/footer.vue";
import {
  getCodeByUrl,
  sectionList,
  comment,
  getWebSocketURL,
} from "@/service/index";
import { getWatchDetail } from "@/service/leetcode";
import { mapState } from "vuex";
export default {
  components: {
    videoPlayer,
    footerLayout,
  },
  data() {
    return {
      content: "",
      content2: "",
      // 保存一级二级回复值
      replyForm: {
        replyValue: "",
      },
      replyForm2: {
        replyValue: "",
      },
      myWangEditor: null,
      // editorOption: {},
      wantReplay: false,
      wantReplay2: false,
      analysisopen: false,
      viewopen: false,
      searchKeyword: "",
      solvingIdea: "",
      remarks: "",
      comment: "",
      haveCode: true,
      tabs: [
        {
          name: "章节评论",
        },
      ],
      TabActive: 0,
      codeShow: true,
      form: {
        desc: "",
      },
      mainviewsTotal: 0,
      mainviews: [],
      lists: [],
      vodPlayerJs: "https://player.polyv.net/resp/vod-player-drm/canary/player.js",
      vid: "",
      player: null,
      code: "",
      activeCode: "Java",
      playItem: {},
      socketPath: "",
      socket: "",
      lockReconnect: false, //是否真正建立连接
      timeout: 1000, //30秒一次心跳
      timeoutObj: null, //心跳心跳倒计时
      serverTimeoutObj: null, //心跳倒计时
      timeoutnum: null, //断开 重连倒计时
      lastTime: 0, //默认上一次点击时间为0
    };
  },
  filters: {
    formatNum: function (value) {
      if (!value) return "";
      return value.substring(0, 2);
    },
  },
  created() {
    this.getWatch();
  },
  mounted() {
    this.$nextTick(() => {
      // 初始化编辑器(章节评价)
      // this.initEditor("#myWangEditor", "content");
      // this.loadPlayerScript(() => {
      //   this.vId = "5c1d111f6370b4e9609aed84576c7cb7_5";
      //   this.loadPlayer();
      // });
      var resize = document.getElementById("resize");
      resize.addEventListener("mousedown", this.handleResize);
    });
  },
  computed: {
    computedWindowHeight() {
      return window.innerHeight - 54 + "px";
    },
    ...mapState(["userInfo"]),
  },
  watch: {
    userInfo: {
      deep: true,
      handler(val) {
        if (this.userInfo.id) {
          getWebSocketURL().then((res) => {
            this.socketPath = res.result;
            this.socketInit();
          });
        }
      },
    },
  },
  methods: {
    socketInit() {
      if (typeof WebSocket === "undefined") {
        alert("您的浏览器不支持socket");
      } else {
        // 实例化socket
        // console.log(this.socketPath)
        this.socket = new WebSocket(this.socketPath);
        // 监听socket连接
        this.socket.onopen = this.socketOpen;
        // 监听socket错误信息
        // this.socket.onerror = this.error
        // 监听socket消息
        this.socket.onmessage = this.getMessage;
      }
    },
    getMessage(msg) {
      this.reset();
      if (msg.data && !JSON.parse(msg.data).resultMessage) {
        window.localStorage.setItem("CSON_PAGE_TOKEN", "");
        window.location.href = "#/";
        window.location.reload();
      }
    },
    throttle() {
      let now = new Date().valueOf();
      if (now - this.lastTime > 1500) {
        this.lastTime = now;
        return true;
      } else {
        return false;
      }
    },
    socketOpen() {
      this.start();
      this.socket.send(
        JSON.stringify({
          method: 0,
          message: {
            userId: this.userInfo.id,
            token: localStorage.getItem("CSON_PAGE_TOKEN"),
          },
        })
      );
    },
    reconnect() {
      //重新连接
      var that = this;
      if (that.lockReconnect) {
        return;
      }
      that.lockReconnect = true;
      //没连接上会一直重连，设置延迟避免请求过多
      that.timeoutnum && clearTimeout(that.timeoutnum);
      that.timeoutnum = setTimeout(function () {
        //新连接
        that.socketInit();
        that.lockReconnect = false;
      }, 1000);
    },
    reset() {
      //重置心跳
      //清除时间
      clearTimeout(this.timeoutObj);
      clearTimeout(this.serverTimeoutObj);
      //重启心跳
      this.start();
    },
    start() {
      //开启心跳
      var self = this;
      self.timeoutObj && clearTimeout(self.timeoutObj);
      self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
      self.timeoutObj = setTimeout(function () {
        //这里发送一个心跳，后端收到后，返回一个心跳消息，
        if (self.socket.readyState == 1) {
          //如果连接正常
          self.socket.send(
            JSON.stringify({
              method: 0,
              message: {
                userId: self.$store.state.userInfo.id,
                token: localStorage.getItem("CSON_PAGE_TOKEN"),
              },
            })
          );
        } else {
          //否则重连
          self.reconnect();
        }
        self.serverTimeoutObj = setTimeout(function () {
          //超时关闭
          self.socket.close();
        }, self.timeout);
      }, self.timeout);
    },
    tabChange(index) {
      this.TabActive = index;
    },
    replay(index) {
      this.$set(this.mainviews[index], "replay", !this.mainviews[index].replay);
      this.$set(this.mainviews[index], "isopen", !this.mainviews[index].isopen);
    },
    childreplay(index, ii) {
      this.$set(
        this.mainviews[index].replies[ii],
        "replay",
        !this.mainviews[index].replies[ii].replay
      );
    },
    handleBlur() {
      if (!this.content) {
        this.wantReplay = false;
      }
    },
    handleBlur2() {
      if (!this.content2) {
        this.wantReplay2 = false;
      }
    },
    handleClick() {
      this.wantReplay = true;
      // 手动获取焦点
      console.log(this.myWangEditor);
      if (this.myWangEditor) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.myWangEditor.$textElem.elems[0].focus();
            this.myWangEditor.isFocus = true;
          }, 500);
        });
      }
    },

    loadPlayerScript(callback) {
      if (!window.polyvPlayer) {
        const myScript = document.createElement("script");
        myScript.setAttribute("src", this.vodPlayerJs);
        myScript.onload = callback;
        document.body.appendChild(myScript);
      } else {
        callback();
      }
    },

    loadPlayer() {
      const polyvPlayer = window.polyvPlayer;
      this.player = polyvPlayer({
        wrap: "#polyvideo",
        width: "100%",
        height: 815,
        vid: this.vId, //需要切换的视频vid
        playsafe: this.playItem.videoToken.playsafe, //新获取的playsafe token
        sign: this.playItem.videoToken.sign, //新获取的sign和ts参数
        ts: this.playItem.videoToken.ts,
        code: this.userInfo.id,
        preview: this.preview,
        forceH5: true,
        autoplay: true,
        viewerInfo: {
          viewerId: this.userInfo.id,
        },
      });
    },
    switchCodeStyle(language) {
      this.codeShow = true;
      this.activeCode = language;
      if (this.playItem.codeUrl) {
        const codeUrl = this.playItem.codeUrl[language];
        getCodeByUrl(codeUrl).then((res) => {
          if (res.success) {
            this.code = res.result;
            console.log(this.code);
          } else {
            this.code = "";
          }
        });
      }
    },
    getWatch() {
      console.log(this.userInfo.email);

      const { id } = this.$route.query;
      getWatchDetail(id)
        .then((res) => {
          if (res.success) {
            this.playItem = res.result;
            this.solvingIdea = res.result.solvingIdea;
            this.remarks = res.result.remarks;
            this.comment = res.result.comment;
            this.preview = res.result.preview;
            this.$store.state.sectionName = res.result.name;
            this.switchCodeStyle("Java");
          }
        })
        .finally(() => {
          // 请求完成初始化编辑器如果没有初始化
          if (!this.player) {
            this.$nextTick(() => {
              this.loadPlayerScript(() => {
                if (this.playItem.videoUrl) {
                  this.vId = this.playItem.videoUrl;
                }
                this.loadPlayer();
              });
            });
          }
        });
    },
    // initEditor(domId, value, callback) {
    //   const editor = new E(domId);
    //   // 配置 onchange 回调函数
    //   editor.config.onchange = (newHtml) => {
    //     if (value === "content") {
    //       this.content = newHtml;
    //     }
    //   };
    //   editor.config.onblur = () => {
    //     if (callback) {
    //       callback();
    //     }
    //   };
    //   // 配置菜单栏，设置不需要的菜单
    //   editor.config.excludeMenus = [
    //     "fontName",
    //     "indent",
    //     "backColor",
    //     "lineHeight",
    //     "link",
    //     "todo",
    //     "justify",
    //     "emoticon",
    //     "image",
    //     "video",
    //     "table",
    //   ];
    //   editor.config.onchangeTimeout = 500;
    //   editor.config.height = 200;
    //   editor.config.showFullScreen = false;
    //   editor.config.showLinkImg = false;
    //   editor.create();
    //   if (value === "content") {
    //     editor.txt.html(this.content);
    //     this.myWangEditor = editor;
    //   }
    // },
    handleResize(e) {
      // 获取需要修改的元素宽度
      var resize = document.getElementById("resize");
      var left = document.getElementById("video-con");
      var right = document.getElementById("code-con");
      var nav_title = document.getElementById("nav_title");
      var test_ser = document.getElementById("test-ser-wrapper");
      var box = document.getElementById("broadcast");

      var startX = e.clientX;
      resize.left = resize.offsetLeft;
      document.onmousemove = (e) => {
        var endX = e.clientX;

        var moveLen = resize.left + (endX - startX);
        var maxT = box.clientWidth - resize.offsetWidth;
        if (moveLen < 650) moveLen = 650;
        if (moveLen > maxT - 350) moveLen = maxT - 350;
        // resize.style.left = moveLen;
        resize.style.right = box.clientWidth - moveLen + "px";
        left.style.width = moveLen + "px";
        right.style.width = box.clientWidth - moveLen + "px";
        nav_title.style.width = box.clientWidth - moveLen - 10 + "px";
        test_ser.style.width = box.clientWidth - moveLen - 10 + "px";
      };
      document.onmouseup = (evt) => {
        document.onmousemove = null;
        document.onmouseup = null;
        resize.releaseCapture && resize.releaseCapture();
      };
      resize.setCapture && resize.setCapture();
      return false;
    },
  },
  beforeDestroy() {
    if (this.player) {
      this.player.destroy();
    }
    var resize = document.getElementById("resize");
    if (resize) {
      resize.removeEventListener("mousedown", this.handleResize);
    }
  },
};
</script>

<style lang="less" scoped>
.nav_title_wrapper {
  display: flex;
}
.nav_title_wrapper li {
  flex: 1;
}
.nav_title {
  width: 100%;
  right: 19px;
  top: 50px;
  z-index: 999;
  background-color: #fff;
}
.house-header .logo {
  margin-top: 0;
}

#codeView {
  height: 100%;

  pre {
    height: 100%;

    code {
      height: 100%;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }
}

.broadcast {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;

  #video-con {
    width: calc(70% - 5px);
    height: 100%;
    overflow-y: auto;
  }

  .video-con .video-body {
    height: 800px;
    width: 100%;
    background: #00101b;
  }

  #resize {
    background: transparent;
    position: fixed;
    right: 30%;
    z-index: 999;
    top: 0;
    width: 10px;
    height: 100%;
    cursor: col-resize;
    background: #fff;
    text-align: center;
    &:hover {
      background: #dae1ff;
    }
  }
  #resize:after {
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle;
    content: "";
  }

  #code-con {
    position: fixed;
    right: 0px;
    top: 50px;
    width: 30%;
    height: calc(100% - 50px);
    z-index: 999;
    background: #fff;
  }

  .code-show {
    margin-top: 0px;
    height: calc(100% - 112px);
  }
}
</style>
