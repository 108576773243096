// leetcode分类顺序表
import request from '../utils/request';


// 获取大分类类型
export async function getSequenceTypes(id) {
    try {
        return await request({
            url: `/leetcodeTable/select?parentId=${id}`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}

// 获取小分类类型
export async function getSequenceData(id, params) {
    try {
        return await request({
            url: `/leetcodeTable/problem/${id}`,
            method: 'get',
            params
        });
    } catch (error) {
        return {};
    }
}

// 发布讨论区
export async function publishDiscussion(data) {
    try {
        return await request({
            url: `/leetcodeNotice`,
            method: 'post',
            data
        });
    } catch (error) {
        return {};
    }
}

// 获取讨论区数据
export async function getDiscussion(params) {
    try {
        return await request({
            url: `/leetcodeNotice`,
            method: 'get',
            params
        });
    } catch (error) {
        return {};
    }
}
// 获取代码问题详情
export async function getCodeDetail(id) {
    try {
        return await request({
            url: `/leetcodeProblem/${id}`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}

// 浏览leetcode题目
export async function browseLeetcode(id) {
    try {
        return await request({
            url: `/browse/leetcode/${id}`,
            method: 'post',
        });
    } catch (error) {
        return {};
    }
}
// 发布解决方案
export async function publishResolution(data) {
    try {
        return await request({
            url: `/leetcodeProblem/addAnswer`,
            method: 'post',
            data
        });
    } catch (error) {
        return {};
    }
}
// 获取题目解决方案
export async function getCodeResolution(id) {
    try {
        return await request({
            url: `/leetcodeProblem/getAnswer/${id}`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}
// 获取观看详情
export async function getWatchDetail(id) {
    try {
        return await request({
            url: `/leetcodeProblem/getAlgorithmDetail/${id}`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}